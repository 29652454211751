@charset "utf-8";

/* mobile web */
@media (max-width: 1024px) {
  main{background-color: white;}

  .h-logo {z-index:10;position:absolute;top:15px;left:24px;display:block;width:224px;height:32px;text-indent:-9999px;background:url('./assets/images/logo_m.png') no-repeat;background-size:auto 32px;}
  .h-logo a {display:block;}
  .h-logo img {height:24px;}
  .btn-menu {display:block;position:absolute;top:20px;right:24px;width:24px;height:24px;z-index:10;background:url('./assets/images/btn_menu.png') no-repeat;background-size:auto 24px;text-indent:-9999px;}

  .cont-wrap .btn-bottom {font-size:0;position:initial}
  .cont-wrap {display:block}
  .cont-wrap .middle-inner {display:block;}

  /* side bar */
  .mobile-nav {overflow-y:scroll;display:block;padding:18px 0 0;position:fixed;top:0;height:100%;width:100%;right:-100%;/*right:0;*/background:#fff;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;z-index:9999;}
  .mobile-nav .close {position:absolute;top:20px;right:24px;}
  .mobile-nav .close img {height:24px;}

  .mobile-nav .gnb li {position:relative;margin-top:40px; padding: 0 32px;}
  .mobile-nav .gnb li a {display:block;padding-left:48px;font-family:'NanumSquareRound';font-size:24px;font-weight:600;line-height:1.42;color:#b1b5c3;transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;cursor:pointer;}
  .mobile-nav .gnb li.on a,
  .mobile-nav .gnb li a:hover,
  .mobile-nav .gnb li a:focus {color:#32d1d1;opacity:1 !important;}
  .mobile-nav .gnb li.nav01 a {background:url('./assets/images/ico_nav01.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav01.on a,
  .mobile-nav .gnb li.nav01 a:hover,
  .mobile-nav .gnb li.nav01 a:focus {background:url('./assets/images/ico_nav01-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav02 a {background:url('./assets/images/ico_nav02.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav02.on a,
  .mobile-nav .gnb li.nav02 a:hover,
  .mobile-nav .gnb li.nav02 a:focus {background:url('./assets/images/ico_nav02-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav03 a {background:url('./assets/images/ico_nav03.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav03.on a,
  .mobile-nav .gnb li.nav03 a:hover,
  .mobile-nav .gnb li.nav03 a:focus {background:url('./assets/images/ico_nav03-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav04 a {background:url('./assets/images/ico_nav04.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav04.on a,
  .mobile-nav .gnb li.nav04 a:hover,
  .mobile-nav .gnb li.nav04 a:focus {background:url('./assets/images/ico_nav04-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  
  .mobile-nav .gnb li.nav05 {border-top: 1px solid #e6e8ec; padding-top:40px }
  .mobile-nav .gnb li.nav05 a {background:url('./assets/images/ico_nav05.png') 0 50% no-repeat;background-size:auto 24px;  }
  .mobile-nav .gnb li.nav05.on a,
  .mobile-nav .gnb li.nav05 a:hover,
  .mobile-nav .gnb li.nav05 a:focus {background:url('./assets/images/ico_nav05-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav06 a {background:url('./assets/images/ico_nav06.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav06.on a,
  .mobile-nav .gnb li.nav06 a:hover,
  .mobile-nav .gnb li.nav06 a:focus {background:url('./assets/images/ico_nav06-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav07 a {background:url('./assets/images/ico_nav07.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb li.nav07.on a,
  .mobile-nav .gnb li.nav07 a:hover,
  .mobile-nav .gnb li.nav07 a:focus {background:url('./assets/images/ico_nav07-curr.png') 0 50% no-repeat;background-size:auto 24px;}
  .mobile-nav .gnb.login {margin-top:35px;border-top:1px solid #e6e8ec;}
  .mobile-nav .lang {font-family: 'NanumSquareRound';display:block;margin-top:40px;padding:43px 40px 0;border-top:1px solid #e6e8ec;}
  .mobile-nav .lang a {font-family: 'NanumSquareRound';position:relative;font-size:24px;font-weight:600;color:#23262f;opacity:.4;}
  .mobile-nav .lang .eng {opacity:1;padding-right:20px;margin-right:20px;}
  .mobile-nav .lang .eng:after {content:'';position:absolute;top:50%;margin-top:-11px;right:0;width:2px;height:22px;background:#b1b1b1;}

  .main-cont header {display:block;}
  .main-cont header .select-box {display:none;}
  .main-cont header .header-inner {padding:16px 0;}
  .main-cont h1 {padding-left:24px;}
  .main-cont h1 img {height:32px;}
  .main-cont .top-wrap {display:none;}
  .main .side-img {display:none;}
  .main .main-info {width:100%;}

  .main .main-info strong {width:260px;padding-top:170px;background:url('./assets/images/logo_ico-type.png') 50% 0 no-repeat;background-size:auto 120px;font-size:24px;line-height:1.33;}
  .main .main-info p {width:260px;margin:32px auto 0;}
  .main .main-info p i {display:none;}
  .main .main-info .btn-type01 {margin-top:100px;}
  .main .main-info .ver {margin-top:56px;}

  .open-wallet {display:block;width:auto;}
  .open-wallet .side {display:none;}
  .open-wallet.cont-wrap .middle-inner {display:block;height:100%;}
  .open-wallet.cont-wrap .cont-box {display:block;width:100%;height:100%;margin-bottom:0;border-radius:0;box-shadow:none;border:none;}
  .open-wallet.cont-wrap .btn-bottom {bottom:32px;}
  .open-wallet .cont-body .lang {display:none;}
  .open-wallet .tit {display:none;}
  .open-wallet .tit + p {margin-top:0;font-size:24px;line-height:1.33;white-space:pre-line;}
  .open-wallet .mobile-br {display:block;}
  .open-wallet .desc {margin-top:24px;}
  .open-wallet .input-box {margin-top:52px;}
  .open-wallet .btn-type01 {margin:16px auto 0;width:auto; max-width:600px; }
  .open-wallet .first-visit {margin-top:24px;}
  
  .popup .pop-wrap {min-width: 335px;max-width: 420px;}
  .create-multisig {padding: 32px 16px !important}
}

