@charset "utf-8";

/* default */
html,body {height:100%;word-break:keep-all;}
body {background-color:#fcfdfd;overflow-x:hidden;/*height:100%;*/font-family: 'NanumSquareRoundOTFB', 'NanumSquareRound','sans-serif';font-size:12px;line-height:1.3;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-osx-font-smoothing:gratranslate3d(0,0,0)yscale;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,fieldset,p,button,iframe,th,td{margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;margin:0;padding:0;}
h1,h2,h3,h4,h5,h6,dt{font-weight:normal;}
img,fieldset,iframe,button{border:0 none;}
button{cursor:pointer;background:none}

button:focus{outline:0;}
li{list-style:none;}
button{border:0 none}
table{ border-collapse:collapse;border-spacing:0; }
th, td{border-collapse:collapse;}
legend, caption, hr{display:none;}
select, input, textarea {}
i, em, u, cite {font-style:normal;}
strong{font-weight:normal;}
img{vertical-align:top;max-width:100%;}
a{color:#4b4c4c;text-decoration:none;outline-offset:-1px;}
table {table-layout:fixed;width:100%;}

/* common */
main {overflow-x:hidden;-webkit-overflow-scrolling:touch;height: 100%;}
article, article > section  {height:100%;}
#contents {position:relative;min-width:320px;min-height:768px;height: 100%;}
#contents a {transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}
#contents a:hover,
#contents a:focus {opacity:.8;}
#root {height:100%}
article {/*padding-top:120px;*/box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.main-cont article {padding:0;}

.btn-menu {display:none;}
.mobile-nav {display:none;}

header {display:none;z-index:999;position:absolute;top:0;left:0;width:100%;background-image:linear-gradient(94deg, #32d1d1 29%, #221a68 142%);}
header .select-box {display:none;}
.header-inner {position:relative;max-width:1120px;margin:0 auto;padding:20px 0;}
.header-inner h1 img {height:40px;}
.header-inner .select-box {position:absolute;top:20px;right:0;}
.header-inner .select-box > div {background:#fff;width:200%;border-radius:5px;border:1px solid #ededed;}
.h-logo {display:none;}

.select-box {position:relative;}
.select-box button {font-size:14px;line-height:40px;color:#fff;padding-right:18px;}
.select-box button:after {content:'';position:absolute;top:50%;margin-top:-2px;right:0;width:8px;height:5px;background:url('./assets/images/ico_dropdown-w.png') 0 0 no-repeat;background-size:auto 5px;transition:transform .2s;-webkit-transition:transform .2s;-ms-transition:transform .2s;}
.select-box button.on:after {transform:rotate(180deg);-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);}
.select-box > div {display:none;z-index:1;position:absolute;text-align:center;}
.select-box > div ul li {margin:13px 0;}
.select-box > div ul li a {display:block;font-size:12px;line-height:1.08;letter-spacing:0.5px;font-weight:800;color:#999;}
.select-box > div ul li a:hover {color:#797980;}

.input-box {margin-top:24px;}
.input-box span {position:relative;display:block;margin-top:8px;padding:16px;border:2px solid #e6e8ec;border-radius:12px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.input-box span:first-child {margin-top:0; display: none}
.input-box input {padding:0;border:none;width:100%;font-size:14px;line-height:1.14;}
.input-box input::placeholder {color:#777e90;}
.input-box .input-password input {display:block;width:calc(100% - 52px);}
.input-box .ico-input {position:absolute;top:50%;margin-top:-12px;right:16px;}
.input-box .ico-eye {display:none;}
.input-box .keyup .ico-lock {display:none;}
.input-box .keyup .ico-eye {display:block;}

.btn-file label {cursor:pointer;display:block;border-radius:90px;background:#3de0e0;font-size:14px;font-weight:bold;line-height:48px;text-align:center;color:#fcfcfd;}
.btn-file input[type="file"] {position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0;}

.text-c {text-align:center;}
.br {display:block;}
.flex-between {display:flex;justify-content:space-between;align-content:center;}
.red {color:#ff5069;}
.blue {color:#3de0e0 !important;}
.mat40 {margin-top:40px !important;}

/* content */
.main-cont header {display:none;}
.main {min-height:1024px;height:100%;display:flex}
.main .side-img {position:relative;overflow:hidden;width:512px;background-image:linear-gradient(153deg, #32d1d1, #221a68);}
.main .side-img span {display:block;padding:56px 0 0 80px;}
.main .side-img span img {height:40px;}
.main .side-img figure {position:absolute;left:50%;margin-left:-512px;bottom:54px;}
.main .side-img figure img {width:1167px;max-width:none;}
.main .main-info {position:relative;display:table;width:calc(100% - 512px);text-align:center;}
.main .main-info .middle-inner {display:table-cell;vertical-align:middle;}
.main .main-info strong {display:block;padding-top:164px;width:360px;margin:0 auto;background:url('./assets/images/logo_ico-type.png') 50% 0 no-repeat;background-size:auto 120px;font-size:32px;font-weight:700;line-height:1.25;color:#32d1d1;}
.main .main-info p {margin-top:24px;font-size:14px;font-weight:700;line-height:1.29;color:#777e90;}
.main .main-info p i {display:block;}
.main .main-info .ver {margin-top:64px;}
.main .main-info .top-wrap {position:absolute;top:56px;right:84px;}
.main .main-info .add-wallet {display:inline-block;vertical-align:middle;padding:0 18px;border-radius:90px;border:solid 2px #e6e8ec;font-size:14px;font-weight:700;line-height:40px;text-align:center;color:#23262f;}
.main .input-box {width:380px;margin:64px auto 0;}
.main .btn-type01 {margin-top:24px !important;}

.main .main-info .select-box {display:inline-block;vertical-align:middle;margin-left:32px;}
.main .main-info .select-box button {color:#777e90;}
.main .main-info .select-box button:after {background:url('./assets/images/ico_dropdown.png') 0 0 no-repeat;background-size:auto 5px;}
.main .main-info .select-box > div ul li a {display:block;font-size:12px;line-height:1.08;letter-spacing:0.5px;font-weight:800;color:#bfbfbf;}
.main .main-info .select-box > div ul li a:hover {color:#797980;}

.tit {display:block;;font-size:18px;line-height:24px;font-weight:800;color:#23262f;}
.tit + p {margin-top:56px;font-size:18px;font-weight:800;line-height:24px;text-align:center;color:#3DE0E0;}
.tit span {width:150px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin-left:8px;font-size:14px;font-weight:600;color:#3de0e0;}
.tit em {margin-left:8px;color:#3de0e0;}
.sub-tit {display:block;font-size:14px;font-weight:700;line-height:1.71;color:#141416;}

.btn-area {margin-top:32px;padding-top:24px;border-top:1px solid #e6e8ec;}
.btn-area .btn-type01 {margin-top:8px !important;}
.btn-type01 {display:block;width:380px;margin:64px auto 0;border-radius:90px;background:#4ae6e6;font-size:14px;font-weight:700;line-height:48px;color:#fcfcfd;text-align:center;}
.btn-type01.navy {background:#23c4c4;}
.btn-type01.red {background:#ff5069;}
.btn-type01 .ico-down {display:inline-block;padding-right:32px;background:url('./assets/images/ico_down.png') 100% 50% no-repeat;background-size:auto 24px;}
.btn-type01.disabled {background:#e6e8ec;}
.first-visit {display:block;margin-top:24px;font-size:12px;font-weight:600;line-height:1.67;color:#777e90;text-align:center;}
.first-visit a {margin-left:5px;color:#32d1d1;}
.ver {font-size:10px;font-weight:700;line-height:2;color:#777e90;text-align:center;}

.cont-wrap {display:table;width:100%;height:100%;}
.cont-wrap .middle-inner {display:table-cell;vertical-align:middle;}
.cont-wrap .cont-box {position:relative;display:flex;width:512px;margin:0 auto;margin-bottom:40px;border-radius:20px;box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.12);-webkit-box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.12);-ms-box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.12);border:solid 1px #e6e8ec;background:#fff;}

.cont-wrap .side {position:relative;background:#3de0e0;border-top-left-radius:20px;border-bottom-left-radius:20px;}
.cont-wrap .side .logo {display:block;padding:32px 12px 24px;}
.cont-wrap .side .logo img {height:40px;}
.cont-wrap .side .bubble {display:none;position:absolute;top:0;right:34px;width:max-content;padding:0 6px;background:#fff;border-radius:4px;box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.2);-webkit-box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.2);-ms-box-shadow:0 2px 8px 0 rgba(0, 0, 0, 0.2);font-size:12px;font-weight:600;line-height:28px;text-align:right;color:#32d1d1;}
.cont-wrap .side .bubble:after {content:'';position:absolute;right:-6px;top:50%;margin-top:-5px;width:8px;height:10px;background:url('./assets/images/bg_path.png') 50% 100% no-repeat;}
.cont-wrap .side .bubble.kor {}
.cont-wrap .side .top {border-top:1px solid rgba(230, 232, 236, .5);border-bottom:1px solid rgba(230, 232, 236, .5);}
.cont-wrap .side ul {padding:24px 20px;}
.cont-wrap .side ul li:first-child {margin-top:0;}
.cont-wrap .side ul li {position:relative;margin-top:24px;}
.cont-wrap .side ul li a {opacity:.5}
.cont-wrap .side ul li img {height:24px;}
.cont-wrap .side .bottom {position:absolute;margin:0;bottom:8px;}
.cont-wrap .side li.on a,
.cont-wrap .side li a:hover,
.cont-wrap .side li a:focus {opacity:1 !important;}
.cont-wrap .side .transmit {position:absolute;top:0;width:8px;height:8px;border-radius:100%;background:#ff5069;}
.cont-wrap .cont-body {width:calc(100% - 64px);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;padding:40px 34px 32px}
.cont-wrap .btn-bottom {font-size:0;position:absolute;left:0;bottom:-64px;width:100%;text-align:center;}
.cont-wrap .btn-bottom a {position:relative;display:inline-block;vertical-align:middle;font-size:14px;font-weight:600;line-height:1.43;color:#32d1d1;}
.cont-wrap .btn-bottom .divider {padding-left:16px;margin-left:16px;}
.cont-wrap .btn-bottom .divider:after {content:'';position:absolute;top:50%;margin-top:-7px;left:0;width:1px;height:14px;background:#b1b1b1;}

.cont-body .first-visit {margin-top:56px}
.cont-body .ver {margin-top:16px;}
.cont-body .back {display:block;padding-left:32px;background:url('./assets/images/ico_back.png') no-repeat;line-height:32px;}
.cont-body .back em {margin-left:8px;color:#3de0e0;}

.cont-body .address-box {margin-top:60px;}
.key-area {display:flex;position:relative;margin-top:8px;padding:16px 22px;border-radius:4px;background:#f4f5f6;}
.key-area p {width: 275px;height:70px;font-size:14px;font-weight:600;line-height:1.71;color:#777e90;word-break: break-all;}
.key-area .btn-box {position:absolute;top:18px;right:26px;}
.key-area button {display:block;/*position:absolute;top:18px;right:26px;*/position: absolute;right: 50px;}
.key-area button img {height:20px;}

.my-wallet.combo .sub-tit {display:inline-block;vertical-align:middle;}
.sub-combo {display:inline-block;vertical-align:middle;}
.sub-combo button {padding-right:24px;}
.sub-combo button:after {width:24px;height:24px;top:0;margin-top:0;background:url(./assets/images/ico_dropdown-combo.png) 0 0 no-repeat;background-size:auto 25px;}
.sub-combo .no-combo {cursor:auto;}
.sub-combo .no-combo:after {background:none;}
.select-box.sub-combo > div {background:#fff;left:-58px;width:384px;text-align:left;border-radius:12px;box-shadow:0 6px 20px 0 rgba(31, 47, 70, 0.2);-webkit-box-shadow:0 6px 20px 0 rgba(31, 47, 70, 0.2);-ms-box-shadow:0 6px 20px 0 rgba(31, 47, 70, 0.2);}
.select-box.sub-combo > div li {margin:0;padding:16px 0px;border-top:1px solid #e6e8ec;}
.select-box.sub-combo > div li:first-child {border:0 none;}
.select-box.sub-combo > div li strong {position:relative;display:block;font-size:14px;font-weight:600;line-height:1.71;color:#141416;}
.select-box.sub-combo > div li strong em {color:#32d1d1;}
.select-box.sub-combo > div li strong i {position:absolute;top:0;right:0;font-size:14px;color:#32D1D1;}
.select-box.sub-combo > div li p {margin-top:5px;font-size:12px;font-weight:600;line-height:1.64;color:#777e90;letter-spacing:normal;word-break: break-all;}
.sub-combo button {font-size:14px;font-weight:600;line-height:1.71;color:#32D1D1;}

.cont-body {position:relative;}
.cont-body .assets-box {margin-top:32px;margin-bottom:80px;padding-top:32px;border-top:1px solid #e6e8ec;}
.cont-body .assets-box li {position:relative;margin-top:8px;padding:15px 15px 15px 36px;border-radius:12px;box-shadow:0 6px 20px -4px rgba(31, 47, 70, 0.12);-webkit-box-shadow:0 6px 20px -4px rgba(31, 47, 70, 0.12);-ms-box-shadow:0 6px 20px -4px rgba(31, 47, 70, 0.12);background:#fff;line-height:1.5;}
.cont-body .assets-box li span,
.cont-body .assets-box li strong {display:block;width:calc(100% - 80px);font-weight:700;}
.cont-body .assets-box li span {font-size:12px;color:#777e90;}
.cont-body .assets-box li strong {font-size:14px;color:#32d1d1;}
.cont-body .assets-box li .btn-type01-s {position:absolute;top:50%;margin-top:-14px;right:16px;display:inline-block;padding:0 10px;font-size:12px;line-height:28px;font-weight:600;color:#fff;border-radius:8px;background:#3de0e0;}
.cont-body .assets-box li.coin {background:url('./assets/images/ico_assets-coin.png') 15px 50% no-repeat;}
.cont-body .assets-box li.mile {background:url('./assets/images/ico_assets-mile.png') 15px 50% no-repeat;}
.cont-body .assets-box li.token {background:url('./assets/images/ico_assets-token.png') 15px 50% no-repeat;}
.cont-body .ico-error {margin-top:16px;padding-left:40px;font-size:12px;font-weight:700;line-height:1.5;color:#ff5069;background:url('./assets/images/ico_error.png') no-repeat 0 50%;background-size:auto 32px;}
.cont-body .ico-info {padding-left:32px;font-size:12px;color:#ff5069;font-weight:bold;background:url('./assets/images/ico_info.png') 0 50% no-repeat;background-size:auto 24px;}
.cont-body .lang {position:absolute;top:41px;right:34px;font-size:0;}
.cont-body .lang a {position:relative;display:inline-block;vertical-align:middle;opacity:0.4;font-weight:bold;font-size:14px;color:#23262f;}
.cont-body .lang .eng {opacity:1;}
.cont-body .lang .kor {padding-left:12px;margin-left:12px;}
.cont-body .lang .kor:after {content:'';position:absolute;left:0;top:50%;margin-top:-6px;width:1px;height:12px;background:#b1b1b1;}

.open-wallet .input-box {margin-top:40px;}
.open-wallet .btn-type01 {margin-top:24px;}
.open-wallet .first-visit {margin-top:69px;}
.open-wallet .desc {width:322px;margin:16px auto 0;font-size:14px;font-weight:600;line-height:1.43;text-align:center;color:#777e90;}



.transfer .cont-body {padding-bottom:80px;}
.transfer .btn-type01 {margin-top:72px;}
.transfer .ver {margin-top:40px;}
.transfer-wrap {margin-top:60px;}
.transfer-wrap li {margin-top:32px;}
.transfer-wrap li label,
.transfer-wrap li .small-tit {display:block;margin-bottom:10px;font-size:12px;font-weight:600;line-height:1;color:#b1b5c3;}
.transfer-wrap .amount span {display:inline-block;width:calc(100% - 84px);}
.transfer-wrap .amount span input {width:calc(100% - 70px);}
.transfer-wrap .amount em {position:absolute;top:0;right:12px;padding-left:25px;background: 0 50%no-repeat;font-size:16px;font-weight:600;line-height:50px;text-align:right;color:#23262f;}
.transfer-wrap .amount .add {margin-left:8px;padding:1px 22px;font-size:14px;line-height:48px;font-weight:600;text-align:center;color:#fff;border-radius:12px;background:#4ae6e6;}
.transfer-wrap .list div {position:relative;margin-top:8px;font-size:0;}
.transfer-wrap .list span {display:inline-block;vertical-align:middle;margin-top:0;}
.transfer-wrap .list .input01 {width:57%;}
.transfer-wrap .list .input02 {width:41%;margin-left:7px;}

.transfer-wrap .ico-clear {display:none;position:absolute;right:0;top:50%;margin-top:-12px;}
.transfer-wrap .clear .ico-clear {display:block;}
.transfer-wrap .clear .input02 {width:33%;}
.transfer-wrap .select-box button {position:relative;display:block;width:100%;padding:0 0 0 52px;border-radius:12px;border:solid 2px #e6e8ec;background:#fff 12px 50% no-repeat;font-size:14px;font-weight:600;line-height:68px;color:#777e90;text-align:left;}
.transfer-wrap .select-box em {position:absolute;top:0;right:44px;font-size:16px;text-align:right;color:#32d1d1;}
.transfer-wrap .select-box button:after {margin-top:-12px;right:12px;width:24px;height:24px;background:url('./assets/images/ico_dropdown2.png') 0 0 no-repeat;background-size:auto 24px;}

.transfer-wrap .select-box > div {width:100%;text-align:left;}
.transfer-wrap .select-box > div ul {margin-top:8px;padding:12px 44px 12px 12px;border-radius:12px;box-shadow:0 6px 20px 0 rgba(31, 47, 70, 0.2);-webkit-box-shadow:0 6px 20px 0 rgba(31, 47, 70, 0.2);-ms-box-shadow:0 6px 20px 0 rgba(31, 47, 70, 0.2);background:#fff;}

.transfer-wrap .select-box > div ul li {position:relative;margin:12px 0;padding-left:40px;background: 0 50% no-repeat;background-size:auto 24px;}
.transfer-wrap .select-box > div ul li a {display:block;font-size:14px;line-height:24px;font-weight:600;letter-spacing:normal;color:#777e90;}
.transfer-wrap .select-box > div ul li em {font-size:14px;right:0;}
.transfer-wrap .select-box > div ul li a:hover {color:#797980;}

.transfer-wrap .sign-info {}
.transfer-wrap .sign-info > span {display:block;font-size:14px;font-weight:600;line-height:1.71;color:#777e90;}
.transfer-wrap .sign-info .red {color:#ff5069;}
.transfer-wrap .multiSign-info {margin-top:8px;padding:8px 0 16px;border:2px solid #e6e8ec;border-radius:12px;text-align:center;font-size:0;}
.transfer-wrap .multiSign-info strong {display:block;font-size:12px;font-weight:600;line-height:2;color:#141416;}
.transfer-wrap .multiSign-info div {display:inline-block;vertical-align:middle;width:125px;}
.transfer-wrap .multiSign-info div:first-child ul {border-left:0;}
.transfer-wrap .multiSign-info ul {border-left:1px solid #e6e8ec;}
.transfer-wrap .multiSign-info li {margin-top:8px;font-size:12px;font-weight:700;line-height:2;color:#777e90;}
.transfer-wrap .multiSign-info li img {height:24px;}
.transfer-wrap .ico-info {margin:16px 0 160px;}
.transfer-wrap .guide-text {margin-top:60px;font-size:14px;font-weight:bold;line-height:1.29;color:#777e90;}

.multisig-wrap {margin-top:40px;}
.multisig-wrap li {position:relative;}
.multisig-wrap .text-black {display:block;font-size:14px;font-weight:600;line-height:1.71;color:#141416;}
.multisig-wrap .text-black + label {margin-top:16px;}
.multisig-wrap .table-list .table-th {margin-top:16px;font-size:0;}
.multisig-wrap .table-list .table-th label {display:inline-block;vertical-align:middle;margin:0 8px 0 0;}
.multisig-wrap .table-list .th01,
.multisig-wrap .table-list .input01 {flex:1;}
.multisig-wrap .table-list .input02 {width:76px;}
.multisig-wrap .table-list input {height:32px;}
.multisig-wrap .table-list li {position:relative;display:flex;margin-top:8px;font-size:0;}
.multisig-wrap .table-list .ico-clear {display:block;}
.multisig-wrap .fee {display:block;margin-top:40px;font-size:14px;font-weight:600;line-height:1.71;color:#141416;}

.multisig-wrap .error {border-color:#ff5069;}
.multisig-wrap .error input::placeholder {color:#ff5069;}
.multisig-wrap .error-notice {position:absolute;z-index:1;padding:12px;font-size:14px;font-weight:bold;line-height:1.29;color:#ff5069;border-radius:12px;box-shadow:0 6px 20px -4px rgba(31, 47, 70, 0.12);-webkit-box-shadow:0 6px 20px -4px rgba(31, 47, 70, 0.12);-ms-box-shadow:0 6px 20px -4px rgba(31, 47, 70, 0.12);background:#fff;}
.multisig-wrap .error-notice.no-bg {padding:0;background:transparent;box-shadow:none;}
.multisig-wrap .error-notice.position01 {top:90px;left:0;}
.multisig-wrap .error-notice.position02 {top:2px;right:-223px;}

.btn-add {display:block;width:100%;margin-top:8px;border-radius:12px;border:solid 1px #3de0e0;background:#3de0e0;}
.btn-add span {padding-right:28px;background:url('./assets/images/bg_add.png') 100% 50% no-repeat;background-size:auto 20px;font-size:14px;font-weight:600;line-height:46px;text-align:center;color:#fff;}



.completed h3 {margin-top:44px;font-size:32px;font-weight:600;line-height:1.38;text-align:center;color:#3de0e0;}
.completed h3 + .transfer-wrap {margin-top:40px;}
.completed .text-line {padding:16px;border-radius:12px;border:solid 2px #e6e8ec;font-size:14px;font-weight:700;line-height:1.14;color:#141416;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box; word-break: break-all;}
.completed .two-box {font-size:0;}
.completed .two-box .text-line {width:42%;display:inline-block;vertical-align:middle;margin-bottom:8px;}
.completed .two-box div:first-child {width:56%;margin-right:7px;}
.completed .ver {margin-top:64px;}

.keypair .tit + div {margin-top:60px;}
.keypair .tit + div p {font-size:14px;font-weight:700;line-height:1.29;color:#777e90;}
.keypair .tit + div p + p {margin-top:20px;}
.keypair .btn-type01 {margin-top:8px;}
.keypair .readonly-wrap {margin-top:48px;margin-bottom:32px;padding-bottom:32px;border-bottom:1px solid #e6e8ec;}
.keypair .btn-key-go {display:block;margin-top:24px;font-size:12px;font-weight:700;line-height:1.67;text-align:center;color:#3de0e0;text-decoration:underline;}
.keypair.key-main .btn-type01 {margin-top:194px;}
.keypair03 .input-box {margin-top:48px;}
.keypair03 .btn-type01 {margin-top:84px;}

.sign-operation .btn-file {margin-top:140px;}

.settings ul {margin-top:56px;}
.settings li {margin-top:40px;display:flex;justify-content:space-between;align-items:center;font-size:14px;font-weight:700;line-height:1.71;color:#141416;}
.settings .top-line {padding-top:32px;margin-top:32px;border-top:1px solid #e6e8ec;}
.settings strong {font-weight:700;}
.settings a {color:#141416;}
.settings span {color:#777e90;}

.settings .radio-box {border:1px solid #b1b5c3;border-radius:8px;font-size:0;}
.settings .radio-box input[type=radio] {display:none;}
.settings .radio-box input[type=radio] + label {display:inline-block;width:65px;font-size:12px;line-height:32px;font-weight:700;color:#777e90;cursor:pointer;text-align:center;}
.settings .radio-box input[type=radio]:checked + label {z-index:1;background:#4AE6E6;color:#fff;border-radius:6px;border:1px solid #4AE6E6;line-height:30px;}

.history-wrap {margin-top:56px;max-height:680px;overflow:scroll;}
.history-wrap .line-box {margin-top:16px;padding:16px 16px 26px;border:1px solid #e6e8ec;border-radius:12px;}
.history-wrap .sub-tit + .line-box {margin-top:8px;}
.history-wrap .line-box li {position:relative;margin-top:18px;font-size:0;}
.history-wrap .line-box li:first-child {margin-top:0;}
.history-wrap .line-box li strong {display:inline-block;vertical-align:top;width:80px;font-size:12px;line-height:28px;font-weight:600;color:#b1b5c3;}
.history-wrap .line-box li div {display:inline-block;font-size:14px;font-weight:600;color:#141416;}
.history-wrap .line-box .copy {position:absolute;top:0;right:0;}
.history-wrap .line-box .copy img {height:20px;}
.history-wrap .line-box .txt-copy {width:calc(100% - 106px);font-size:12px;color:#777e90;}
.txt-copy,.txt-copy p{word-break: break-all;}
.status-type {display:inline-block;border-radius:8px;padding:0 10px;font-size:12px;line-height:28px;color:#fff;}
.status-type.yellow {background:#ffbe37;}
.status-type.green {background:#58bd7d;}
.status-type.red {background:#ff5069;}

.popup {display:none;position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(20, 20, 22, 0.5);z-index:9999;}
.popup .tit em {margin-left:5px;color:#3de0e0;}
.popup .pop-wrap {
  position:absolute;top:50%;left:50%;padding:32px 24px 24px;max-width:360px;min-width:296px;border-radius:20px;border:solid 1px #e6e8ec;background:#fff;
  box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;
  transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);
  box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.6);-webkit-box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.6);-ms-box-shadow:0 64px 64px -48px rgba(31, 47, 70, 0.6);
}
.pop-wrap .ico-error {padding-top:40px;background:url('./assets/images/ico_error.png') 50% 0 no-repeat;background-size:auto 32px;font-size:14px;font-weight:700;line-height:1.29;color:#777e90;}
.pop-wrap .btn-type01 {width:100%;margin-top:24px;line-height:40px;}

.pop-wrap .btn-type01.small {display:inline-block;padding:0 34px;font-weight:600;line-height:40px; color:#fcfcfd;width:auto;}
.pop-wrap .btn-type01.small.blue {background:#3de0e0;}
.pop-wrap .btn-type01.small.red {background:#ff5069;}

.pop-tit {display:block;font-size:18px;font-weight:600;color:#23262f;}

.pop-transfer > div {margin-top:28px;}
.pop-transfer .small-tit {font-size:12px;font-weight:600;line-height:1;color:#b1b5c3;}
.pop-transfer .pop-address div {margin-top:10px;width:312px;padding:16px;border-radius:4px;background:#f4f5f6;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;word-break: break-all;}
.pop-transfer .pop-address p {font-size:14px;font-weight:600;line-height:1.71;color:#777e90;}
.pop-transfer .pop-assets ul {margin-top:10px;padding:20px 24px 20px 12px;border-radius:12px;border:solid 2px #e6e8ec;}
.pop-transfer .pop-assets li {margin-top:16px;font-size:14px;line-height:32px;font-weight:600;}
.pop-transfer .pop-assets li:first-child {margin-top:0;}
.pop-transfer .pop-assets li strong {padding-left:40px;font-weight:600;color:#777e90;background: 0 59% no-repeat;background-size:auto 32px;}
.pop-transfer .pop-assets li em {font-size:16px;font-weight:600;color:#32d1d1;}
.pop-transfer .notice {margin-top:32px;padding-top:32px;border-top:1px solid #e6e8ec;font-size:14px;line-height:1.29;text-align:center;color:#777e90;font-weight:600;}
.pop-transfer .input-box {margin-top:16px;}
.pop-transfer .btn-type01.small {margin:0}

.create-multisig {padding:32px 40px 24px !important;}
.create-multisig span {display:block;margin-top:16px;font-size:14px;font-weight:bold;line-height:1.29;color:#ff5069;}
.create-multisig p {margin-top:16px;font-size:14px;font-weight:bold;line-height:1.29;color:#777e90;}
.create-multisig .flex-between {display:block;font-size:0;}
.create-multisig .btn-type01.small {margin:28px 8px 0;}

.rules-wrap {height:450px;overflow-y:scroll;margin-top:50px;}
.rules-box {margin-top:40px;}
.rules-box:first-child {margin-top:0;}
.rules-box .rules-tit {font-size:14px;font-weight:600;line-height:1.71;color:#141416;}
.rules-box p,
.rules-box li {font-size:14px;line-height:1.29;color:#777e90;}
.rules-box p {margin-top:20px;}
.rules-box ol {padding-left:20px;text-indent:-20px;}
.rules-box li {margin-top:10px;}
.rules-box li ol {margin-top:5px;}
.rules-box li ol li {margin-top:0;}
.rules-box a {text-decoration:underline;font-weight:bold;}
