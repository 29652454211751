body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

input {
  font-family: 'NanumSquareRoundOTFB', 'NanumSquareRound', 'Open Sans', 'sans-serif';
  outline: none;
}

i,
em,
strong,
button,
a,
input,
span,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6 input::placeholder {
  font-family: 'NanumSquareRoundOTFB', 'NanumSquareRound', 'Open Sans', 'sans-serif';
}
.word-break-all {
  word-break: break-all;
}
